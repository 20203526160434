body {
    --font: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-family: var(--font);
    --color: #000;
    --color-emphasis: #222;
    --bg: #f4f4f4;
    --border: #dbdbdb;
    --link: #00b7ff;
    --link-hover: #009bd8;
    --theme: #00dd25;
    --theme-hover: #00b71f;
    background-color: var(--bg);
    color: var(--color);
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
}
html {
    margin: 0;
    padding: 0;
    height: 100%;
}
code {
    font-family: monospace;
    background-color: var(--border);
    padding: 0.1em 0.3em;
    border-radius: 0.3em;
}
:where(*) {
    scroll-behavior: smooth;
    font-family: var(--font);
}
@media ( prefers-color-scheme: dark ) {
    body {
        --color: #fff;
        --color-emphasis: #ddd;
        --bg: #1a1a1a;
        --border: #2b2b2b;
        --theme: #00580f;
        --theme-hover: #00570e;
    }
}

h1 {
    font-size: 1.5em;
    margin: 0;
    padding: 0;
}

h2 {
    font-size: 1.25em;
    margin: 0;
    padding: 0;
}

h3 {
    font-size: 1em;
    margin: 0;
    padding: 0;
}

h4 {
    font-size: 0.875em;
    margin: 0;
    padding: 0;
}

h5 {
    font-size: 0.75em;
    margin: 0;
    padding: 0;
}

h6 {
    font-size: 0.625em;
    margin: 0;
    padding: 0;
}

.link {
    text-decoration: underline;
    border: none;
    display: inline;
    background: none;
    cursor: pointer;
    font-size: 1em;
}

a, .link {
    color: var(--link);
    text-underline-offset: 0.1em;
}

a:hover, .link:hover {
    color: var(--link-hover);
}
.no-line {
    text-decoration: none !important;
}

form {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
}

#chat-form input {
    flex: 1;
    padding: 0.5em;
    border-radius: 0.5em;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid var(--border);
}
#chat-form button {
    padding: 0.5em;
    border-radius: 0.5em;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: none;
    background-color: var(--theme);
    border-left-style: none !important;
    font-weight: bold;
    border: 1px solid var(--border);
}
#chat-form button:hover {
    background-color: var(--theme-hover);
}

* {
    box-sizing: border-box;
    transition: outline-offset 0.2s ease;
}
:focus-visible {
    outline: 2px solid #00ffdd7c;
    outline-offset: 2px;
}

#chat {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 1em;
}

#chat-form-container {
    position: sticky;
    box-shadow: 0px -9px 8px -8px #0f0f0f;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1em;
    background-color: var(--bg);
    border-top: 2px solid var(--border);
}


button {
    cursor: pointer;
}
.message > .user {
    font-weight: bold;
    --user-color:  ;
    color: var(--user-color);
}
.message > .user.ping {
    border-radius: 0.5em;
    color: var(--user-color);
    filter: brightness(0.8);
    padding: 0.05em 0.3em;
    padding-top: 0;
    cursor: pointer;
    background-color: rgb(from var(--user-color) r g b / 0.3);    
}

.message > .user.ping:hover {
    color: var(--user-color);
    background-color: rgb(from var(--user-color) r g b / 0.5);
}
.message.error {
    color: #800000;
}
@media ( prefers-color-scheme: dark ) {
    .message.error {
        color: #ff5f5f;
    }
}
time {
    font-size: small;
}
#localstorage-consent {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.3em 1em;
    background-color: #006b96;
    border-top: 2px solid #04a6db;
    color: #fff;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: 1fr 0;
    align-items: center;
    justify-content: space-between;
}
#localstorage-consent p {
    display: inline;
}
#localstorage-consent button {
    appearance: none;
    background-color: transparent;
    border: none;
    text-decoration: none;
    font-weight: bold;
    height: 100%;
}
#localstorage-consent button:hover {
    text-decoration: underline;
}
small {
    font-size: small;
    opacity: 0.5;
}
#chat-tip-next {
    --link: var(--color);
    --link-hover: var(--color-emphasis);
    float: inline-end;
}

img.emoji {
    height: 1em;
    width: 1em;
    margin: 0 .05em 0 .1em;
    vertical-align: -0.1em;
}
.link-decor {
    margin-left: 0.1em;
    font-size: 0.8em;
}

#settings-button {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0.5em;
    --link: var(--color);
    --link-hover: var(--color-emphasis);
    cursor: pointer;
    z-index: 100;
    
}
#settings-button::after {
    content: 'Settings';
    display: inline;
    margin-left: 0.5em;
    opacity: 0.5;
    transition: all 0.2s ease;
    font-weight: 600;
}
#settings-button:hover::after {
    opacity: 1;
}
#settings-button svg {
    transform: rotate(0deg);
    transition: all 0.2s ease;
}
#settings-button:hover svg {
    transform: rotate(180deg);
}

#tl-bar {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0.5em;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    gap: 0.2em;
}